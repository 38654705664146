/** @jsx jsx */
import { jsx, Spinner, Text } from "theme-ui";
import QRCode from "react-qr-code";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import { formatSys2OdooDate } from "sites-common/utils/nextMonth";
import {
  doFormatShortDate,
  formatOdooDate2Sys,
} from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import DLWLayout from "../../layouts/dlw-mobile";
import { base26 } from "../../lib/base";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const { siteUrl } = getMandatoryEnv(["siteUrl"]);

const d = new Date();
d.setDate(d.getDate() + 1);
const today1 = formatSys2OdooDate(d);

const dx = new Date();
dx.setDate(dx.getDate() - 2);

const today2x = formatSys2OdooDate(dx);

const trainerQrCodesCollection = "TrainerQrCodes";

const generateShort = (name, d1, n1) => {
  const d2 = formatOdooDate2Sys(d1);
  const n = d2.getTime() - 1520000000000;
  let out = "";
  // let sumrem = 0;
  let itr = n;
  while (itr > 0) {
    const div = Math.floor(itr / 26);
    const rem = itr % 26;
    out = String.fromCharCode(65 + rem) + out;
    itr = div;
    // sumrem += rem;
  }
  const x = `${name
    .replace("-", "")
    .replace("_", "")
    .replace(".", "")
    .replace("@", "")}`.toUpperCase();

  const odd = x.length % 2;
  return `${x.slice(0, 1)}${
    x.length > 3 ? x.slice(2 - odd, 3 - odd) : x.slice(1, 2)
  }${out.slice(2 + n1 - odd, 3 + n1 - odd)}${out.slice(
    out.length - 1 - n1,
    out.length - n1
  )}`;
};

const SavedTrainerCode = ({ email, id, url, code, expires }) => {
  const firestore = useFirestore();
  const [shortcode, setShortcode] = useState("");
  const [error, setError] = useState("");

  const myQrCodes = useSelector((state) => state.firestore.ordered.TQC);

  useEffect(() => {
    const listenerSettings = {
      collection: trainerQrCodesCollection,
      storeAs: "TQC",
      where: ["t", "==", id],
    };
    firestore.setListener(listenerSettings);
    return function cleanup() {
      firestore.unsetListener(listenerSettings);
    };
  }, [firestore, id]);

  useEffect(() => {
    if (myQrCodes) {
      const expiredCodes = myQrCodes.filter((xr) => xr.expires < today2x);
      expiredCodes.forEach((xx) => {
        firestore.delete({ collection: trainerQrCodesCollection, doc: xx.id });
      });
    }
  }, [myQrCodes, firestore]);

  useEffect(() => {
    if (myQrCodes) {
      const tryQr = (n) => {
        const shortcode1 = generateShort(email, expires, n);
        const docId = `${trainerQrCodesCollection}/${shortcode1}`;

        if (
          myQrCodes.some(
            (r) => r.t === id && r.id === shortcode1 && r.expires >= today2x
          )
        ) {
          setShortcode(shortcode1);
          return;
        }

        firestore.get(docId).then((r) => {
          if (r.exists) {
            const rd = r.data();
            if (rd.t === id) {
              setShortcode(shortcode1);
            } else if (n === 2) {
              setError("Short Code Temporarily Unavailable");
            } else {
              tryQr(n + 1);
            }
          } else {
            firestore
              .set(docId, { t: id, code, expires })
              .then(() => {
                setShortcode(shortcode1);
              })
              .catch((e) => setError(e.toString()));
          }
        });
      };
      tryQr(0);
    }
  }, [id, code, expires, setError, firestore, email, myQrCodes]);

  if (shortcode && !error) {
    return (
      <div>
        <div sx={{ m: 2 }}>or</div>

        <div style={{ fontSize: "0.85rem", m: 2 }}>
          <i>Register at Link:</i> <u>{url}</u>
        </div>
        <div style={{ fontSize: "0.85rem" }}>
          <i>Trainer Referral Code:</i> <b>{shortcode}</b>
        </div>
        <div style={{ color: "#777", fontSize: "0.75rem" }}>
          expires {`${doFormatShortDate(expires)}`}
        </div>
      </div>
    );
  }
  if (error) {
    return (
      <Text variant="error">
        <br />
        {error}
      </Text>
    );
  }
  return <Spinner />;
};

SavedTrainerCode.propTypes = {
  email: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  expires: PropTypes.string.isRequired,
};

function QRCodePage() {
  const { srcmProfile } = useAuth();

  const { id, email } = srcmProfile || {};

  // const [dt1, setDt1] = useState(todayP2);
  const dt2 = today1;

  const dt = parseInt(dt2.replaceAll("-", ""), 10);
  // const expires = parseInt(dt2.replaceAll("-", ""), 10);

  const url1 = `${siteUrl}/qr`;
  const url2 = `${base26(id * 100000000 + dt)}`;
  const url = `${url1}?code=${url2}`;

  return (
    <DLWLayout>
      <div sx={{ textAlign: "center", mb: 3 }}>
        {/* <div sx={{p:2, m:2, bg: "muted"}}>
          <Label>Date of First Guided Meditation Session</Label>
          <DynamicField type="date" label="Sitting Date" name="dt1" value={dt1} onChange={setDt1}/>

        </div> */}
        <Text variant="title">Trainer Referral Code for New Introductions</Text>
        <br />
        <Text variant="subtitle">
          to be shared with the practitioners for self registration and ID card
        </Text>
        <br />
        <br />
        <QRCode value={url} />
        <br />
        <div style={{ fontSize: "0.85rem", m: 2 }}>
          <i>Direct Referral Link:</i> <u>{url}</u>
        </div>
        <div style={{ color: "#777", fontSize: "0.75rem" }}>
          expires {`${doFormatShortDate(dt2)}`}
        </div>
        <SavedTrainerCode
          email={email}
          url={url1}
          code={url2}
          expires={dt2}
          id={id}
        />

        <hr />
        <div sx={{ textAlign: "left" }}>
          <Text variant="title">Please note:</Text>
          <Text variant="subtitle">
            <ul>
              <li>
                Please share the referral code only with the new seeker whom you
                have given introductory sittings and whom you would like to
                issue ID Card.
              </li>
              <li>
                ID Card requests would get auto-approved for the new seekers
                with any of the above referral codes / links.
              </li>
              <li>These are valid typically only for one day.</li>
              <li>
                You can come back to this page to generate a new link / code to
                share anytime.
              </li>
              <li>
                Any number of users can use this referral code and get ID cards
                automatically. Please ensure your referral code does not get
                misused.
              </li>
            </ul>
          </Text>
        </div>
      </div>
    </DLWLayout>
  );
}

export default QRCodePage;
